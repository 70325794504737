<template>
  <van-popup
    v-model="showPicker"
    class="fill-in-wrap-pop"
    position="right"
    style="width: 100%; height: 100%"
  >
    <div class="fill-in-wrap-contact">
      <div class="head">联系人信息</div>
      <div class="tips m_b_32 m_t_32 dis_flex ju_con_cen ali_it_cen">
        请填写至少{{ config.dirceLineNumber }}位直系亲属（父母、子女、配偶）
        <!-- 暂时屏蔽 -->
        <!-- <van-button @click="pushContact" plain class="btn" size="small"
          >+新增</van-button
        > -->
      </div>

      <div class="content fillin-upload-ctn">
        <div
          class="card m_b_32"
          v-for="(contactItem, index) in form.contacts"
          :key="index"
        >
          <van-cell :title="`第${index + 1}位联系人`" icon="friends-o">
            <!-- 暂时屏蔽 -->
            <!-- <template #right-icon>
              <div class="dis_flex ali_it_cen ju_con_cen">
                <van-icon
                  v-if="form.contacts.length > 5"
                  name="clear"
                  color="#ee0a24"
                  @click="removeContact(index)"
                />
              </div>
            </template> -->
            <!-- <template #right-icon>
              {{ "实名通过" }}
            </template> -->
          </van-cell>
          <van-field
            :value="contactItem.contactRelationStr"
            label="关系"
            placeholder="请选择"
            @click="
              setPopConfig(contactItem, 'contactRelation', 'contactRelationStr')
            "
            required
            readonly
            is-link
            input-align="right"
          />
          <van-field
            v-model="contactItem.contactName"
            label="姓名"
            placeholder="请输入姓名"
            :maxlength="20"
            input-align="right"
            required
          />

          <van-field
            v-model="contactItem.contactPhone"
            label="电话"
            placeholder="请输入电话"
            :maxlength="11"
            required
            input-align="right"
          />
        </div>
      </div>
      <div class="btns">
        <van-button size="small" @click="showPicker = false">返回</van-button>
        <van-button type="info" size="small" @click="saveFn">保存</van-button>
      </div>
    </div>
    <PopupPicker
      v-model="pickerView"
      :columns="contactEnums"
      @confirm="pickerConfirm"
    />
  </van-popup>
</template>
<script>
import "@vant/touch-emulator";
// import { ImagePreview } from "vant";
import {
  userContactsEnum,
  userContactsSave,
  getContactConfig
  // getUserRelation
} from "@/api/contact";
import { REGEX_MOBILE } from "@/utils/regex.js";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    // 需要填的表单内容
    detail: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    // Upload: () => import("@/common/Upload.vue"),
    PopupPicker: () => import("@/views/home/components/PopupPicker.vue")
  },
  watch: {
    showPicker(v) {
      if (v) {
        // console.log("传入的表单内容", this.fields);
        try {
          this.form = {
            contacts: []
          };
          this.init();
        } catch (e) {
          console.log("查看报错", e);
          this.form = { contacts: [] };
          this.init();
        }
      } else {
        this.form = { contacts: [] };
        // this.init();
      }
    }
  },
  computed: {
    showPicker: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  },
  data() {
    return {
      pickerView: false, // 下拉框弹出层
      pickerDataItem: null, // 当前操作的数据项

      currentKey: undefined, // 下拉框弹出层 - 对应数据的key
      currentTextKey: undefined, // 下拉框弹出层 - 对应字符的key
      contactEnums: [], // 拉胯弹出层数据源
      form: { contacts: [] }, // 表单
      config: {} // 配置
    };
  },
  created() {
    this.getEnums();
    // this.form = { contacts: [] };
    // this.init();
  },
  methods: {
    async getEnums() {
      try {
        const res = await userContactsEnum();
        this.contactEnums = (res.data || []).map((item) => ({
          value: item.value,
          text: item.displayName,
          direct: item.direct
        }));
      } catch (error) {
        this.$errMsg(error);
      }
    },
    async init() {
      try {
        let { data } = await getContactConfig(this.detail.bizNo);
        const { extraData: config = {} } = data || {};
        this.config = config
          ? {
              contactNumber: Number(config.contactNumber),
              dirceLineNumber: Number(config.dirceLineNumber)
            }
          : {};
        // let { data: list } = await getUserRelation(this.detail.bizNo);
        // this.form.contacts = this.form.contacts.concat(list);
        // for (let i = 1; i <= config.contactNumber - list.length; i++) {
        for (let i = 1; i <= config.contactNumber; i++) {
          this.pushContact();
        }
      } catch (error) {
        this.$errMsg(error.message || error);
      }
    },
    // 下拉框-设置弹出层枚举
    setPopConfig(pickerDataItem, keyname, textKeyname) {
      this.currentKey = keyname;
      this.currentTextKey = textKeyname;
      this.pickerDataItem = pickerDataItem;

      this.pickerView = true;
    },
    // 下拉框确认
    pickerConfirm({ value, text }) {
      // console.log("sdsdsd", value, text);

      this.$set(this.pickerDataItem, this.currentKey, value);
      this.$set(this.pickerDataItem, this.currentTextKey, text);
    },
    //新增联系人信息
    pushContact() {
      this.form.contacts.push({
        // contactsAddress: null,
        contactName: "",
        contactPhone: "",
        contactRelation: null,
        source: 1 // 	app提交的不可修改，2:后台添加
      });
    },
    // 是否包含生僻字
    containsBigWord(name) {
      if (typeof TextEncoder === "undefined") {
        return false;
      }
      for (const ch of name) {
        if (new TextEncoder().encode(ch).length > 3) {
          return ch;
        }
      }
      return false;
    },
    removeContact(index) {
      this.form.contacts.splice(index, 1);
    },
    async saveFn() {
      try {
        if (!this.config.contactNumber) throw "暂无联系人数量";
        let { form, contactEnums } = this;
        let { contacts } = form;
        let directPicker = contactEnums.filter((item) => item.direct);
        let directArr = contacts.filter((item) => {
          return (
            directPicker.findIndex(
              (sec) => sec.value == item.contactRelation
            ) >= 0
          );
        });

        if (directArr.length < this.config.dirceLineNumber) {
          throw `请选择至少${this.config.dirceLineNumber}位直系亲属`;
        }

        for (let i = 0; i < contacts.length; i++) {
          let contact = contacts[i];
          // if (
          //   contact.contactRelation ||
          //   contact.contactName ||
          //   contact.contactPhone
          // ) {
          //   // 三个之中任意填写一个，都需要校验
          // 产品说全都要校验，5个都必填
          if (!contact.contactRelation) {
            throw `请选择第${i + 1}位联系人关系`;
          }
          if (contact.contactName === "") {
            throw `请输入第${i + 1}位联系人姓名`;
          }
          if (this.containsBigWord(contact.contactName)) {
            throw `第${i + 1}位联系人姓名包含生僻字`;
          }
          if (contact.contactPhone === "") {
            throw `请输入第${i + 1}位联系人电话`;
          }
          if (!REGEX_MOBILE.test(contact.contactPhone)) {
            throw `第${i + 1}位联系人电话输入有误`;
          }
          for (var k = i + 1; k < contacts.length; k++) {
            let kContact = contacts[k];
            if (contact.contactPhone === kContact.contactPhone) {
              throw `请不要输入重复的电话`;
            }
          }
          // }
        }

        let canEmitArr = this.form.contacts.filter((item) => item.contactName);
        await userContactsSave({
          bizNo: this.detail.bizNo,
          list: canEmitArr || []
        });
        this.$emit("update", this.form);
        this.showPicker = false;
      } catch (error) {
        // TODO: 某种报错的情况下需要将部分联系人禁用掉，然后重新提交
        this.$errMsg(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.fill-in-wrap-pop {
  :deep(.fill-in-wrap-contact) {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #f5f7f9;
    .card {
      background: #ffffff;
      margin-top: 44rpx;
      padding-bottom: 20rpx;
      .del {
        opacity: 0.85;
        line-height: 50rpx;
        font-size: 16rpx;
        margin: 0 30rpx;
      }
    }

    .head {
      height: 96px;
      text-align: center;
      line-height: 96px;
      font-size: 32px;
      font-weight: 600;
      color: #333;
      border-bottom: 1px solid #efefef;
    }
    .content {
      flex: 1;
      overflow-y: auto;

      .color-r {
        color: red;
      }
      .color-blue {
        color: rgb(24, 144, 255);
      }
    }
    .fillin-upload-ctn-title {
      min-width: 65vw;
    }
    .btns {
      border-top: 1px solid #efefef;
      height: 100px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      box-shadow: 0 -5px 10px #efefef;
      .van-button {
        min-width: 160px;
      }
    }
  }
}
</style>
